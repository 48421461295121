import isBoolean from "lodash/isBoolean";
import isNil from "lodash/isNil";
import { useMemo } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import { FilterParamNames } from "~/constants/search";
import { useFiltersContext } from "~/providers/FiltersProvider";
import { customFieldIdFromUrlParam } from "~/utils/filters/urlParamsToFiltersUtils";
export const useFilterParams = ()=>{
    const { filters: userDefinedFilters, immutableFilters, accountId } = useFiltersContext();
    const filters = useDeepCompareMemo(()=>({
            ...userDefinedFilters,
            ...immutableFilters
        }), [
        userDefinedFilters,
        immutableFilters
    ]);
    const boardParam = useMemo(()=>{
        const boardId = filters[FilterParamNames.boardId];
        if (boardId) {
            return {
                is: boardId
            };
        }
        return null;
    }, [
        filters
    ]);
    const libraryParam = useMemo(()=>{
        const libraryId = filters[FilterParamNames.libraryId];
        if (libraryId) {
            return {
                is: libraryId
            };
        }
        return null;
    }, [
        filters
    ]);
    const onlyInLibrariesParam = useMemo(()=>{
        const onlyInLibraries = filters[FilterParamNames.onlyInLibraries];
        if (isBoolean(onlyInLibraries)) {
            return {
                is: onlyInLibraries
            };
        }
        return null;
    }, [
        filters
    ]);
    const typesParam = useMemo(()=>{
        var _filters_FilterParamNames_type;
        if ((_filters_FilterParamNames_type = filters[FilterParamNames.type]) === null || _filters_FilterParamNames_type === void 0 ? void 0 : _filters_FilterParamNames_type.length) {
            return filters[FilterParamNames.type];
        }
        return [];
    }, [
        filters
    ]);
    const customFieldsParam = useMemo(()=>{
        const customFields = [];
        if (filters.customFields) {
            Object.keys(filters.customFields).forEach((paramName)=>{
                const cfId = customFieldIdFromUrlParam(paramName);
                if (!cfId) return;
                if (paramName.startsWith(FilterParamNames.cfIdAnd)) {
                    customFields.push({
                        id: cfId,
                        filter: {
                            and: filters.customFields[paramName]
                        }
                    });
                } else if (paramName.startsWith(FilterParamNames.cfIdOr)) {
                    customFields.push({
                        id: cfId,
                        filter: {
                            or: filters.customFields[paramName]
                        }
                    });
                } else if (paramName.startsWith(FilterParamNames.cfIdNot)) {
                    customFields.push({
                        id: cfId,
                        filter: {
                            not: filters.customFields[paramName]
                        }
                    });
                }
            });
        }
        return customFields;
    }, [
        filters.customFields
    ]);
    const createdDateParam = useMemo(()=>{
        if (filters[FilterParamNames.recordedDateStart] || filters[FilterParamNames.recordedDateEnd]) {
            const createdDate = {};
            if (filters[FilterParamNames.recordedDateStart]) {
                createdDate.start = filters[FilterParamNames.recordedDateStart];
            }
            if (filters[FilterParamNames.recordedDateEnd]) {
                createdDate.end = filters[FilterParamNames.recordedDateEnd];
            }
            return createdDate;
        }
        return null;
    }, [
        filters
    ]);
    const updatedDateParam = useMemo(()=>{
        if (filters[FilterParamNames.modifiedDateStart] || filters[FilterParamNames.modifiedDateEnd]) {
            const updatedDate = {};
            if (filters[FilterParamNames.modifiedDateStart]) {
                updatedDate.start = filters[FilterParamNames.modifiedDateStart];
            }
            if (filters[FilterParamNames.modifiedDateEnd]) {
                updatedDate.end = filters[FilterParamNames.modifiedDateEnd];
            }
            return updatedDate;
        }
        return null;
    }, [
        filters
    ]);
    const uploadedDateParam = useMemo(()=>{
        if (filters[FilterParamNames.uploadedDateStart] || filters[FilterParamNames.uploadedDateEnd]) {
            const uploadedDate = {};
            if (filters[FilterParamNames.uploadedDateStart]) {
                uploadedDate.start = filters[FilterParamNames.uploadedDateStart];
            }
            if (filters[FilterParamNames.uploadedDateEnd]) {
                uploadedDate.end = filters[FilterParamNames.uploadedDateEnd];
            }
            return uploadedDate;
        }
        return null;
    }, [
        filters
    ]);
    const takenDateParam = useMemo(()=>{
        if (filters[FilterParamNames.takenDateStart] || filters[FilterParamNames.takenDateEnd]) {
            const takenDate = {};
            if (filters[FilterParamNames.takenDateStart]) {
                takenDate.start = filters[FilterParamNames.takenDateStart];
            }
            if (filters[FilterParamNames.takenDateEnd]) {
                takenDate.end = filters[FilterParamNames.takenDateEnd];
            }
            return takenDate;
        }
        return null;
    }, [
        filters
    ]);
    const tagsParam = useMemo(()=>{
        var _filters_FilterParamNames_tagIdAnd, _filters_FilterParamNames_tagIdOr, _filters_FilterParamNames_tagIdNot;
        if ((_filters_FilterParamNames_tagIdAnd = filters[FilterParamNames.tagIdAnd]) === null || _filters_FilterParamNames_tagIdAnd === void 0 ? void 0 : _filters_FilterParamNames_tagIdAnd.length) {
            return {
                and: filters[FilterParamNames.tagIdAnd]
            };
        } else if ((_filters_FilterParamNames_tagIdOr = filters[FilterParamNames.tagIdOr]) === null || _filters_FilterParamNames_tagIdOr === void 0 ? void 0 : _filters_FilterParamNames_tagIdOr.length) {
            return {
                or: filters[FilterParamNames.tagIdOr]
            };
        } else if ((_filters_FilterParamNames_tagIdNot = filters[FilterParamNames.tagIdNot]) === null || _filters_FilterParamNames_tagIdNot === void 0 ? void 0 : _filters_FilterParamNames_tagIdNot.length) {
            return {
                not: filters[FilterParamNames.tagIdNot]
            };
        }
        return null;
    }, [
        filters
    ]);
    const peopleParam = useMemo(()=>{
        var _filters_FilterParamNames_personIdAnd, _filters_FilterParamNames_personIdOr;
        if ((_filters_FilterParamNames_personIdAnd = filters[FilterParamNames.personIdAnd]) === null || _filters_FilterParamNames_personIdAnd === void 0 ? void 0 : _filters_FilterParamNames_personIdAnd.length) {
            return {
                and: filters[FilterParamNames.personIdAnd]
            };
        } else if ((_filters_FilterParamNames_personIdOr = filters[FilterParamNames.personIdOr]) === null || _filters_FilterParamNames_personIdOr === void 0 ? void 0 : _filters_FilterParamNames_personIdOr.length) {
            return {
                or: filters[FilterParamNames.personIdOr]
            };
        }
    }, [
        filters
    ]);
    const importedKeywordsParam = useMemo(()=>{
        var _filters_FilterParamNames_importedKeywordAnd, _filters_FilterParamNames_importedKeywordOr, _filters_FilterParamNames_importedKeywordNot;
        if ((_filters_FilterParamNames_importedKeywordAnd = filters[FilterParamNames.importedKeywordAnd]) === null || _filters_FilterParamNames_importedKeywordAnd === void 0 ? void 0 : _filters_FilterParamNames_importedKeywordAnd.length) {
            return {
                and: filters[FilterParamNames.importedKeywordAnd]
            };
        } else if ((_filters_FilterParamNames_importedKeywordOr = filters[FilterParamNames.importedKeywordOr]) === null || _filters_FilterParamNames_importedKeywordOr === void 0 ? void 0 : _filters_FilterParamNames_importedKeywordOr.length) {
            return {
                or: filters[FilterParamNames.importedKeywordOr]
            };
        } else if ((_filters_FilterParamNames_importedKeywordNot = filters[FilterParamNames.importedKeywordNot]) === null || _filters_FilterParamNames_importedKeywordNot === void 0 ? void 0 : _filters_FilterParamNames_importedKeywordNot.length) {
            return {
                not: filters[FilterParamNames.importedKeywordNot]
            };
        }
        return null;
    }, [
        filters
    ]);
    const sourcesParam = useMemo(()=>{
        var _filters_FilterParamNames_source;
        if ((_filters_FilterParamNames_source = filters[FilterParamNames.source]) === null || _filters_FilterParamNames_source === void 0 ? void 0 : _filters_FilterParamNames_source.length) {
            return {
                or: filters[FilterParamNames.source]
            };
        }
        return null;
    }, [
        filters
    ]);
    const camerasParam = useMemo(()=>{
        const cameraModels = filters[FilterParamNames.cameraModel];
        const cameraMake = filters[FilterParamNames.cameraMake];
        if (cameraMake || !!(cameraModels === null || cameraModels === void 0 ? void 0 : cameraModels.length)) {
            if (cameraModels === null || cameraModels === void 0 ? void 0 : cameraModels.length) {
                // we allow selecting only one make and multiple models for that make
                return {
                    or: cameraModels === null || cameraModels === void 0 ? void 0 : cameraModels.map((model)=>{
                        var _filters_FilterParamNames_cameraMake;
                        return {
                            make: (_filters_FilterParamNames_cameraMake = filters[FilterParamNames.cameraMake]) !== null && _filters_FilterParamNames_cameraMake !== void 0 ? _filters_FilterParamNames_cameraMake : undefined,
                            model
                        };
                    })
                };
            } else {
                // if there is no model, send only make
                return {
                    or: [
                        {
                            make: filters[FilterParamNames.cameraMake]
                        }
                    ]
                };
            }
        }
        return null;
    }, [
        filters
    ]);
    const uploadersParam = useMemo(()=>{
        var _filters_FilterParamNames_uploaderId;
        if ((_filters_FilterParamNames_uploaderId = filters[FilterParamNames.uploaderId]) === null || _filters_FilterParamNames_uploaderId === void 0 ? void 0 : _filters_FilterParamNames_uploaderId.length) {
            return {
                or: filters[FilterParamNames.uploaderId]
            };
        }
        return null;
    }, [
        filters
    ]);
    const copyrightsParam = useMemo(()=>{
        var _filters_FilterParamNames_copyright;
        if ((_filters_FilterParamNames_copyright = filters[FilterParamNames.copyright]) === null || _filters_FilterParamNames_copyright === void 0 ? void 0 : _filters_FilterParamNames_copyright.length) {
            return {
                or: filters[FilterParamNames.copyright]
            };
        }
        return null;
    }, [
        filters
    ]);
    const creatorsParam = useMemo(()=>{
        var _filters_FilterParamNames_creator;
        if ((_filters_FilterParamNames_creator = filters[FilterParamNames.creator]) === null || _filters_FilterParamNames_creator === void 0 ? void 0 : _filters_FilterParamNames_creator.length) {
            return {
                or: filters[FilterParamNames.creator]
            };
        }
        return null;
    }, [
        filters
    ]);
    const colorsParam = useMemo(()=>{
        var _filters_FilterParamNames_color;
        if ((_filters_FilterParamNames_color = filters[FilterParamNames.color]) === null || _filters_FilterParamNames_color === void 0 ? void 0 : _filters_FilterParamNames_color.length) {
            return {
                or: filters[FilterParamNames.color]
            };
        }
        return null;
    }, [
        filters
    ]);
    const bookmarkedParam = useMemo(()=>{
        if (accountId && filters[FilterParamNames.bookmarked]) {
            return {
                is: accountId
            };
        }
    }, [
        filters,
        accountId
    ]);
    const isOnBoardsParam = useMemo(()=>{
        if (!isNil(filters[FilterParamNames.isOnBoards])) {
            return {
                is: !!filters[FilterParamNames.isOnBoards]
            };
        }
    }, [
        filters
    ]);
    const hasVersionsParam = useMemo(()=>{
        if (!isNil(filters[FilterParamNames.hasMultipleVersions])) {
            return {
                is: !!filters[FilterParamNames.hasMultipleVersions]
            };
        }
    }, [
        filters
    ]);
    const untaggedParam = useMemo(()=>{
        if (!isNil(filters[FilterParamNames.untagged])) {
            return {
                is: !!filters[FilterParamNames.untagged]
            };
        }
    }, [
        filters
    ]);
    const hasOpenCommentsParam = useMemo(()=>{
        if (!isNil(filters[FilterParamNames.hasOpenComments])) {
            return {
                is: !!filters[FilterParamNames.hasOpenComments]
            };
        }
    }, [
        filters
    ]);
    const extensionsParam = useMemo(()=>{
        var _filters_FilterParamNames_ext;
        if ((_filters_FilterParamNames_ext = filters[FilterParamNames.ext]) === null || _filters_FilterParamNames_ext === void 0 ? void 0 : _filters_FilterParamNames_ext.length) {
            return {
                or: filters[FilterParamNames.ext]
            };
        }
        return null;
    }, [
        filters
    ]);
    const locationParam = useMemo(()=>{
        const countriesFilter = filters[FilterParamNames.country];
        const statesFilter = filters[FilterParamNames.state];
        const citiesFilter = filters[FilterParamNames.city];
        if (!(countriesFilter === null || countriesFilter === void 0 ? void 0 : countriesFilter.length) && !(statesFilter === null || statesFilter === void 0 ? void 0 : statesFilter.length) && !(citiesFilter === null || citiesFilter === void 0 ? void 0 : citiesFilter.length)) return null;
        const locationParam = {};
        if (countriesFilter === null || countriesFilter === void 0 ? void 0 : countriesFilter.length) {
            locationParam.country = {
                or: countriesFilter
            };
        }
        if (statesFilter === null || statesFilter === void 0 ? void 0 : statesFilter.length) {
            locationParam.state = {
                or: statesFilter
            };
        }
        if (citiesFilter === null || citiesFilter === void 0 ? void 0 : citiesFilter.length) {
            locationParam.city = {
                or: citiesFilter
            };
        }
        return locationParam;
    }, [
        filters
    ]);
    const videoFrameRatesParam = useMemo(()=>{
        const frameRates = filters[FilterParamNames.videoFrameRate];
        if (frameRates === null || frameRates === void 0 ? void 0 : frameRates.length) {
            return {
                or: frameRates.map((value)=>Number(value))
            };
        }
        return null;
    }, [
        filters
    ]);
    const videoAspectRatiosParam = useMemo(()=>{
        var _filters_FilterParamNames_videoAspectRatio;
        if ((_filters_FilterParamNames_videoAspectRatio = filters[FilterParamNames.videoAspectRatio]) === null || _filters_FilterParamNames_videoAspectRatio === void 0 ? void 0 : _filters_FilterParamNames_videoAspectRatio.length) {
            return {
                or: filters[FilterParamNames.videoAspectRatio]
            };
        }
        return null;
    }, [
        filters
    ]);
    const audioCodingsParam = useMemo(()=>{
        var _filters_FilterParamNames_audioCoding;
        if ((_filters_FilterParamNames_audioCoding = filters[FilterParamNames.audioCoding]) === null || _filters_FilterParamNames_audioCoding === void 0 ? void 0 : _filters_FilterParamNames_audioCoding.length) {
            return {
                or: filters[FilterParamNames.audioCoding]
            };
        }
        return null;
    }, [
        filters
    ]);
    const audioSampleRatesParam = useMemo(()=>{
        const audioSampleRate = filters[FilterParamNames.audioSampleRate];
        if (audioSampleRate === null || audioSampleRate === void 0 ? void 0 : audioSampleRate.length) {
            return {
                or: audioSampleRate.map((value)=>Number(value))
            };
        }
        return null;
    }, [
        filters
    ]);
    const params = useDeepCompareMemo(()=>({
            audioSampleRatesParam,
            audioCodingsParam,
            customFieldsParam,
            updatedDateParam,
            createdDateParam,
            takenDateParam,
            bookmarkedParam,
            colorsParam,
            uploadersParam,
            boardParam,
            sourcesParam,
            tagsParam,
            uploadedDateParam,
            typesParam,
            extensionsParam,
            isOnBoardsParam,
            hasVersionsParam,
            untaggedParam,
            hasOpenCommentsParam,
            libraryParam,
            onlyInLibrariesParam,
            importedKeywordsParam,
            copyrightsParam,
            creatorsParam,
            camerasParam,
            videoFrameRatesParam,
            locationParam,
            videoAspectRatiosParam,
            peopleParam
        }), [
        peopleParam,
        audioSampleRatesParam,
        audioCodingsParam,
        importedKeywordsParam,
        creatorsParam,
        camerasParam,
        videoFrameRatesParam,
        locationParam,
        videoAspectRatiosParam,
        boardParam,
        bookmarkedParam,
        colorsParam,
        createdDateParam,
        customFieldsParam,
        takenDateParam,
        extensionsParam,
        hasOpenCommentsParam,
        hasVersionsParam,
        untaggedParam,
        isOnBoardsParam,
        sourcesParam,
        tagsParam,
        typesParam,
        updatedDateParam,
        uploadedDateParam,
        uploadersParam,
        libraryParam,
        onlyInLibrariesParam,
        copyrightsParam,
        videoAspectRatiosParam
    ]);
    return params;
};
