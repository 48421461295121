import isNil from "lodash/isNil";
import { FilterParamNames } from "~/constants/search";
export const getExcludedFiltersFromImmutableFilters = (fastFilters)=>{
    var _fastFilters_tagIdAnd, _fastFilters_tagIdOr, _fastFilters_tagIdNot, _fastFilters_type, _fastFilters_uploaderId, _fastFilters_source, _fastFilters_ext, _fastFilters_color, _fastFilters_FilterParamNames_importedKeywordAnd, _fastFilters_FilterParamNames_importedKeywordOr, _fastFilters_FilterParamNames_importedKeywordNot;
    const excludedFilterTypes = new Set();
    const excludedOtherFilters = new Set();
    const excludedCustomFields = new Set();
    if (!!((_fastFilters_tagIdAnd = fastFilters.tagIdAnd) === null || _fastFilters_tagIdAnd === void 0 ? void 0 : _fastFilters_tagIdAnd.length) || !!((_fastFilters_tagIdOr = fastFilters.tagIdOr) === null || _fastFilters_tagIdOr === void 0 ? void 0 : _fastFilters_tagIdOr.length) || !!((_fastFilters_tagIdNot = fastFilters.tagIdNot) === null || _fastFilters_tagIdNot === void 0 ? void 0 : _fastFilters_tagIdNot.length)) {
        excludedFilterTypes.add("tags");
    }
    if (!!((_fastFilters_type = fastFilters.type) === null || _fastFilters_type === void 0 ? void 0 : _fastFilters_type.length)) excludedFilterTypes.add("type");
    if (!!((_fastFilters_uploaderId = fastFilters.uploaderId) === null || _fastFilters_uploaderId === void 0 ? void 0 : _fastFilters_uploaderId.length)) excludedFilterTypes.add("uploader");
    if (!!((_fastFilters_source = fastFilters.source) === null || _fastFilters_source === void 0 ? void 0 : _fastFilters_source.length)) excludedFilterTypes.add("source");
    if (!!((_fastFilters_ext = fastFilters.ext) === null || _fastFilters_ext === void 0 ? void 0 : _fastFilters_ext.length)) excludedFilterTypes.add("extension");
    if (!!((_fastFilters_color = fastFilters.color) === null || _fastFilters_color === void 0 ? void 0 : _fastFilters_color.length)) excludedFilterTypes.add("color");
    if (!!fastFilters.uploadedDateEnd || !!fastFilters.uploadedDateStart) excludedFilterTypes.add("dateUploaded");
    if (!!fastFilters.recordedDateEnd || !!fastFilters.recordedDateStart) excludedFilterTypes.add("dateCreated");
    if (!!fastFilters.modifiedDateEnd || !!fastFilters.modifiedDateStart) excludedFilterTypes.add("dateModified");
    if (!!fastFilters[FilterParamNames.takenDateEnd] || !!fastFilters[FilterParamNames.takenDateStart]) excludedFilterTypes.add("dateTaken");
    if (!!((_fastFilters_FilterParamNames_importedKeywordAnd = fastFilters[FilterParamNames.importedKeywordAnd]) === null || _fastFilters_FilterParamNames_importedKeywordAnd === void 0 ? void 0 : _fastFilters_FilterParamNames_importedKeywordAnd.length) || !!((_fastFilters_FilterParamNames_importedKeywordOr = fastFilters[FilterParamNames.importedKeywordOr]) === null || _fastFilters_FilterParamNames_importedKeywordOr === void 0 ? void 0 : _fastFilters_FilterParamNames_importedKeywordOr.length) || !!((_fastFilters_FilterParamNames_importedKeywordNot = fastFilters[FilterParamNames.importedKeywordNot]) === null || _fastFilters_FilterParamNames_importedKeywordNot === void 0 ? void 0 : _fastFilters_FilterParamNames_importedKeywordNot.length)) {
        excludedFilterTypes.add("importedKeywords");
    }
    if (!!fastFilters[FilterParamNames.copyright]) excludedFilterTypes.add("copyright");
    if (!!fastFilters[FilterParamNames.creator]) excludedFilterTypes.add("creator");
    if (!!fastFilters[FilterParamNames.country]) excludedFilterTypes.add("country");
    if (!!fastFilters[FilterParamNames.city]) excludedFilterTypes.add("city");
    if (!!fastFilters[FilterParamNames.state]) excludedFilterTypes.add("state");
    if (!isNil(fastFilters.bookmarked)) excludedOtherFilters.add("is-favorited");
    if (!isNil(fastFilters.hasMultipleVersions)) excludedOtherFilters.add("has-versions");
    if (!isNil(fastFilters.hasOpenComments)) excludedOtherFilters.add("has-open-comments");
    if (!isNil(fastFilters.untagged)) excludedOtherFilters.add("is-untagged");
    if (!isNil(fastFilters.isOnBoards)) excludedOtherFilters.add("is-on-boards");
    if (fastFilters.customFields) {
        Object.keys(fastFilters.customFields).forEach((cfId)=>excludedCustomFields.add(cfId));
    }
    return {
        excludedFilterTypes,
        excludedOtherFilters,
        excludedCustomFields
    };
};
